import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
const Dashboard = loadable(() => import('./pages/Dashboard/Dashboard'));
const Settings = loadable(() => import('./pages/Settings/Settings'));
const Items = loadable(() => import('./pages/Items/Items'));
const Item = loadable(() => import('./pages/Item/Item'));
const SubscriptionConfirmation = loadable(() => import('./pages/SubscriptionConfirmation/SubscriptionConfirmation'));
const PrivacyPolicy = loadable(() => import('./pages/PrivacyPolicy/PrivacyPolicy'));
const Support = loadable(() => import('./pages/Support/Support'));

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <PrivateRoute path="/settings">
        <Settings />
      </PrivateRoute>
      <PrivateRoute path="/items/:id">
        <Item />
      </PrivateRoute>
      <PrivateRoute path="/items" exact>
        <Items />
      </PrivateRoute>
      <PrivateRoute path="/subscription-confirmation" exact>
        <SubscriptionConfirmation />
      </PrivateRoute>
      <PrivateRoute path="/support">
        <Support />
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <Dashboard />
      </PrivateRoute>
      <Route path="*">
        <div>Not found</div>
      </Route>
    </Switch>
  );
};

export default Routes;