import { AppProvider } from "@shopify/polaris";
import { Provider, ClientRouter } from "@shopify/app-bridge-react";
import "@shopify/polaris/dist/styles.css";
import translations from "@shopify/polaris/locales/en.json";
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import './App.css';
import Routes from './Routes';
import PublicRoutes from './PublicRoutes';
import RoutePropagator from './RoutePropagator';
import NotificationProvider from './components/providers/Notification';
import useShopOrigin from './hooks/useShopOrigin';
import useLoginUrl from './hooks/useLoginUrl';
import useHost from './hooks/useHost';
import FullPageLoading from './components/FullPageLoading';
import isInIframe from './utils/isInIframe';
import { useEffect, useMemo } from "react";

function MyProvider() {
  const history = useHistory();

  return (
    <>
      <ClientRouter history={history} />
      <Routes />
    </>
  );
}

function EmbeddedApp() {
  const host = useHost();
  const loginUrl = useLoginUrl();

  useEffect(() => {
    if (!isInIframe()) {
      window.location.href = loginUrl 
    }
  }, [loginUrl])

  if (!isInIframe()) {
    return <FullPageLoading message="Please wait ..." />
  }

  return (
    <Provider
      config={{
        apiKey: (process.env.REACT_APP_SHOPIFY_API_KEY as string),
        host: host || 'public',
        forceRedirect: true,
      }}
    >
      <RoutePropagator />
      <MyProvider />
    </Provider>
  )
}

const useNeedEmbed = () => {
  const shopOrigin = useShopOrigin();

  return useMemo(() => {
    return shopOrigin
  }, [shopOrigin])
}

function App() {
  const needEmbed = useNeedEmbed()
  
  return (
    <Router>
      <AppProvider i18n={translations}>
        <NotificationProvider>
          {needEmbed && (
            <EmbeddedApp />
          )}
          {!needEmbed && (
            <PublicRoutes />
          )}
        </NotificationProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
